import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import { history } from "../../../store";
import { INTEGER_SIZE } from "../../../_constants/data";
import { IMatch } from "../../../_types/common";
import { notify, notifySwagger } from "../../../_actions/notification";
import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import withMenu from "../../../containers/HOC/WithMenu";
import { MainContainer, Skeleton } from "../../../components";
import { IGlobalStore } from "../../../_reducers/reducers";
import Title from "../../../sharedComponents/Title";
import ViewForm from "./ViewForm";
import * as ordersApi from "../../../_api/orders";
import { IViewState } from "./types";
import { removeHashSign } from "src/_utils/misc";
import { withRouter } from "react-router-dom";

const Position = {
  Tabs: styled.div`
    display: flex;
    width: 100%;
    padding: 20px;
    ${Skeleton.Line} {
      margin: 4px 20px 0 0;
    }
  `,

  Subtitle: styled.div`
    display: flex;
    flex-direction: row;
    div {
      margin-right: 16px;
    }
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
};

export interface IViewProps extends IConnectedActions, IWithGrantedProps {
  match: IMatch;
  location?: any;
}

class View extends React.Component<IViewProps, IViewState> {
  public orderForm: Formik;

  public static packingSlipReconciliationValidationSchema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        quantityReceived: Yup.number()
          .typeError("must be a number")
          .integer("must be a number")
          .min(0, `can't be negative`)
          .max(INTEGER_SIZE, `can't be grater than ${INTEGER_SIZE}`),
      })
    ),
  });

  constructor(props) {
    super(props);

    this.state = {
      orderStatus: "loading",
      isChanged: false,
    };
    this.handleClickBack = this.handleClickBack.bind(this);
  }

  componentDidMount() {
    this.setState({
      orderStatus: "loading",
      packingSlipStatus: this.props.location?.state?.packingSlipStatus,
    });

    const statusFromList = this.props.location?.state?.packingSlipStatus;
    // console.log("statusFromList =", statusFromList);

    ordersApi
      .getOtherPackingSlipForEdit(this.props.match.params.orderId)
      .then((order) => {
        this.setState({
          creationTime: order.creationTime,
          location: order.location,
          creatorUser: order.creatorUser,
          items: order.items.map((item) => ({
            ...item,
            reason: item.reason || "",
          })),
          id: order.id,
          orderStatus: "done",
          number: order.number,

          packingSlipStatus: statusFromList,
        });
      })
      .catch((e) => {
        this.props.notifySwagger(e, "error");
      });
  }

  handleClickBack(): void {
    history.goBack();
  }

  render() {
    return (
      <MainContainer
        scale="md"
        centered
        renderTitle={
          <Title label="Packing Slip Reconciliation">
            {this.state.orderStatus === "loading" && (
              <Position.Subtitle>
                <Title.Element>
                  <Skeleton.Line size="lg" />
                </Title.Element>
              </Position.Subtitle>
            )}
            {this.state.orderStatus !== "loading" && (
              <Position.Subtitle>
                <Title.Element>
                  Buyer:{" "}
                  {this.state.creatorUser &&
                    this.state.creatorUser.displayValue}
                </Title.Element>
                <Title.Element>
                  Order #: {removeHashSign(this.state.number)}
                </Title.Element>
                <Title.Element>
                  Date/Time:{" "}
                  {moment(this.state.creationTime).format("MM/DD/YYYY LT")}
                </Title.Element>
              </Position.Subtitle>
            )}
          </Title>
        }
      >
        <Position.Content>
          <Formik
            enableReinitialize
            initialValues={this.state}
            validationSchema={View.packingSlipReconciliationValidationSchema}
            ref={(ref) => (this.orderForm = ref)}
            onSubmit={() => {}}
            render={(props) => {
              return <ViewForm {...props} onClickBack={this.handleClickBack} />;
            }}
          />
        </Position.Content>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationId: number;
  locationName: string;
  catalogId: number;
}

interface IConnectedActions {
  notify: typeof notify;
  notifySwagger: typeof notifySwagger;
}

export default withMenu(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      locationId: (state) => state.currentUser.locationInfo.id,
      locationName: (state) => state.currentUser.locationInfo.name,
      catalogId: (state) => state.currentUser.catalogId,
    }),
    {
      notify,
      notifySwagger,
    }
  )(withGranted(withRouter(View))),
  false
);
