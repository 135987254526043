import * as React from "react";
import * as Yup from "yup";
import styled from "styled-components";

import { PackingSlipOrderItemDto } from "../../../service-proxies";

import Number from "../../../components/Number";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";

import { NUMBER_MESSAGE, INT32_MAX } from "../../../_constants/validation";
import { getMaxNumberLengthMessage } from "src/_utils/validation";

const ListNumber = function ({ id, ...props }) {
  function handleChange(e) {
    props.onChange(id, e);
  }
  function handleBlur(e) {
    props.onBlur(id, e);
  }
  return <Number {...props} onChange={handleChange} onBlur={handleBlur} />;
};

interface IReconciliationModalState {
  items: PackingSlipOrderItemDto[];
  errors?: {};
  isValid: boolean;
}

interface IReconciliationModalProps {
  items: PackingSlipOrderItemDto[];
  onClickReject: () => void;
  onClickResolve: (items: PackingSlipOrderItemDto[]) => void;
}

const QuantityWarning = styled.div`
  color: #f57c00;
  font-size: 12px;
  margin-top: 4px;
`;

const ModalDescription = styled.p`
  margin-bottom: 20px;
  color: #666;
  font-size: 14px;
`;

class ReconciliationModal extends React.Component<
  IReconciliationModalProps,
  IReconciliationModalState
> {
  static initialState: IReconciliationModalState = {
    items: [],
    errors: {},
    isValid: true,
  };

  static validationScheme = Yup.object().shape({
    items: Yup.array(
      Yup.object().shape({
        quantityReturned: Yup.number()
          .max(INT32_MAX, getMaxNumberLengthMessage(INT32_MAX))
          .min(0, "Quantity must be positive")
          .typeError(NUMBER_MESSAGE)
          .nullable(),
      })
    ),
  });

  constructor(props) {
    super(props);
    this.state = ReconciliationModal.initialState;

    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleResolve = this.handleResolve.bind(this);
  }

  componentDidMount() {
    const items = this.props.items.map((item) => ({
      ...item,
      quantityReturned: item.quantity,
    }));

    this.setState({ items });
  }

  async validate() {
    try {
      await ReconciliationModal.validationScheme.validate(this.state);
      this.setState({ errors: {}, isValid: true });
    } catch (e) {
      const newState = { ...this.state };
      newState.errors[e.path] = e.message;
      this.setState({ ...newState, isValid: false });
    }
  }

  handleChange(id, value) {
    const items = [...this.state.items];
    items[id].quantityReturned = value;

    this.setState({ items }, () => {
      this.validate();
    });
  }

  handleBlur(id, e) {
    const value = e.target.value;
    const items = [...this.state.items];
    items[id].quantityReturned = value;

    this.setState({ items }, () => {
      this.validate();
    });
  }

  async handleResolve() {
    await this.validate();
    if (this.state.isValid) {
      this.props.onClickResolve(this.state.items);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal.Body>
          {this.state.items.some((item) => item.isNeedToReturn) && (
            <ModalDescription>
              The quantity indicated below is the quantity ordered.  Please select
              the quantity you wish to return.
            </ModalDescription>
          )}
          {this.state.items.map((item, index) => {
            if (!item.isNeedToReturn && item.isRecived) {
              return null;
            }

            // Show quantity input if item was received
            if (item.isRecived) {
              return (
                <React.Fragment key={index}>
                  <ListNumber
                    label={item.product.name}
                    value={item.quantityReturned}
                    placeholder="Quantity"
                    id={index}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    error={
                      this.state.errors[`items[${index}].quantityReturned`]
                    }
                    positiveOnly
                    integer
                  />
                  {item.quantityReturned > item.quantity && (
                    <QuantityWarning>
                      Warning: Return quantity exceeds the original order quantity
                      of {item.quantity}.
                    </QuantityWarning>
                  )}
                </React.Fragment>
              );
            }

            // If item wasn't received, just display it under the main list (we'll also break it out below)
            return null;
          })}

          {/* New section for items not received */}
          {this.state.items.some((item) => !item.isRecived) && (
            <>
              <h3 style={{ marginTop: 20 }}>Items Not Received</h3>
              {this.state.items
                .filter((item) => !item.isRecived)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <div style={{ fontWeight: 500, marginTop: 10 }}>
                      {item.product.name}
                    </div>
                    <QuantityWarning>
                      This item was marked as not received.
                    </QuantityWarning>
                  </React.Fragment>
                ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.FooterElement />
          <Modal.FooterElement>
            <Button primary onClick={this.props.onClickReject}>
              Cancel
            </Button>
            <Button disabled={!this.state.isValid} onClick={this.handleResolve}>
              Submit
            </Button>
          </Modal.FooterElement>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

export default ReconciliationModal;
