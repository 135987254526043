import * as React from 'react';

import withMenu from '../../HOC/WithMenu';

import { history } from '../../../store';

import {
  Button,
  Field,
  Select,
  FormGrid as Grid,
  Link,
} from '../../../components';

import { IEditVendorState } from './Edit';
import {
  FormikActions,
  FormikComputedProps,
  FormikHandlers,
  FormikState,
} from 'formik';
import { SearchOutputDto } from 'src/service-proxies';
import { VENDORS_TRANSMISSION_TYPES } from 'src/_constants/data';

interface IFormProps extends
  FormikHandlers,
  FormikState<IEditVendorState>,
  FormikActions<IEditVendorState>,
  FormikComputedProps<IEditVendorState> {
  isLoading: boolean;
  isNew: boolean;
}

interface IFormState {
  startDateFocused?: boolean;
}

class Form extends React.Component<IFormProps, IFormState> {

  constructor(props) {
    super(props);

    this.state = {
      startDateFocused: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleUseEDIChange = this.handleUseEDIChange.bind(this);

  }

  handleUseEDIChange(option: SearchOutputDto) {
    this.props.setValues({
      ...this.props.values,
      transmissionType: option.id,
    });
  }

  handleCancel() {
    history.goBack();
  }

  handleDiscard() {
    history.goBack();
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;

    return <form noValidate onSubmit={handleSubmit}>
      <Grid>
        <Grid.Body>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="name"
                label="Name"
                placeholder="Name..."
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.name &&
                  errors.name
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Select
                name="transmissionType"
                label="Transmission Type"
                placeholder="Transmission Type..."
                labelKey="displayValue"
                valueKey="id"
                value={values.transmissionType}
                options={VENDORS_TRANSMISSION_TYPES}
                onChange={this.handleUseEDIChange}
                disabled={this.props.isLoading}
                error={
                  touched.transmissionType &&
                  errors.transmissionType as string
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required={(values.transmissionType === 1)}
                type="text"
                name="email"
                label="Email"
                placeholder="Email..."
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.email &&
                  errors.email
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
              <Grid.Cell>
                <Field
                  required
                  type="text"
                  name="vendorId"
                  label="Vendor ID"
                  placeholder="Vendor ID..."
                  value={values.vendorId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={this.props.isLoading}
                  error={touched.vendorId && errors.vendorId}
                />
              </Grid.Cell>
              <Grid.Cell />
            </Grid.Row>

        </Grid.Body>
        <Grid.Footer>
          <Grid.FooterElement />
          <Grid.FooterElement>
            {
              this.props.isNew ?
                <Link.Button
                  primary
                  onClick={this.handleCancel}
                  scale="lg"
                >
                  Cancel
                </Link.Button> :
                <Link.Button
                  primary
                  onClick={this.handleDiscard}
                  scale="lg"
                >
                  Discard
                </Link.Button>
            }
            <Button
              type="submit"
              disabled={this.props.isLoading}
              scale="lg"
              preloader={this.props.isLoading}
            >
              {this.props.isNew ? 'Submit' : 'Save'}
            </Button>
          </Grid.FooterElement>
        </Grid.Footer>
      </Grid>
    </form>;
  }
}

export default withMenu(Form, false);
