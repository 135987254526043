import React from "react";
import styled from "styled-components";
import {
  prompt as openConfirm,
  alert,
  openCustomModalCallback,
  IOpenCustomModalCallback,
  IOpenAlert,
  IOpenConfirmPrompt
} from "../../../_actions/modal";
import { connect } from "react-redux";
import {
  Field,
  Telephone,
  FormGrid as Grid,
  ClientUnitsTree,
  Checkbox,
  Number,
  Select,
  Button
} from "../../../components";
import { AddressOverrideModal } from "./AddressOverride/Modal";
import { removeFromArray } from "../../../_utils/data";
import * as spendingTermApi from "../../../_api/spendingTerm";
import { IValues } from "./types";
import {
  FormikActions,
  FormikState,
  FormikHandlers,
  FormikComputedProps
} from "formik";
import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import { sendEmail } from "../../../_api/resetPassword";
import { getSwaggerErrorResponse } from "src/_utils/errorHandling";
import debounce from "lodash.debounce";

const Position = {
  PasswordToggle: styled.div`
    margin-bottom: 14px;
  `,
  UnlimitedCheckbox: styled.div`
    margin-top: 33px;
  `
};

interface IUserFormInfoProps
  extends FormikState<IValues>,
    FormikActions<IValues>,
    FormikHandlers,
    FormikComputedProps<IValues>,
    IWithGrantedProps {
  values: IValues;
  isNew: boolean;
  disabled: boolean;
  isLoading: boolean;
  openModal: IOpenCustomModalCallback;
  openConfirm: IOpenConfirmPrompt;
  alertModal: IOpenAlert;
}

class UserFormInfo extends React.Component<IUserFormInfoProps> {
  constructor(props) {
    super(props);

    this.loadSpendingTerm = this.loadSpendingTerm.bind(this);

    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.handleAddressOverrideClick = this.handleAddressOverrideClick.bind(
      this
    );
    this.handleAddressOverrideChange = this.handleAddressOverrideChange.bind(
      this
    );
    this.handleAddressOverrideDelete = this.handleAddressOverrideDelete.bind(
      this
    );
  }

  componentDidMount() {
    this.loadSpendingTerm();
  }

  async loadSpendingTerm() {
    let defaultSpendingTerm = null;
    this.props.setFieldValue("spendingTermStatus", "loading");
    if (!this.props.values.spendingTerm) {
      defaultSpendingTerm = await spendingTermApi.getDefaultSpendingTerm();
    }
    const spendingTermOptions = await spendingTermApi.getSpendingTerm();
    this.props.setValues({
      ...this.props.values,
      spendingTerm: this.props.values.spendingTerm
        ? this.props.values.spendingTerm
        : {
            id: defaultSpendingTerm.id,
            displayValue: defaultSpendingTerm.displayValue
          },
      spendingTermOptions: spendingTermOptions.items,
      spendingTermStatus: "loaded"
    });
  }

  handlePhoneNumberChange(value) {
    this.props.setFieldValue("phoneNumber", value);
  }

  handleSendEmail(path: string) {
    const pathArr = path.split("/");
    const userId = pathArr[pathArr.length - 1];
    const input = {
      userId: parseInt(userId)
    };
    sendEmail(input)
      .then(() =>
        this.props
          .alertModal(
            "Password reset email has been sent to the user.",
            "Success!"
          )
          .catch(() => {})
      )
      .catch(err =>
        this.props
          .alertModal(getSwaggerErrorResponse(err).error.message, "Error!")
          .catch(() => {})
      );
  }

  handleAddressOverrideChange(addressOverride) {
    const oldAddressOverride = this.props.values.addressOverrides.find(
      a => a.clientUnitId === addressOverride.clientUnitId
    );
    if (oldAddressOverride) {
      oldAddressOverride.name = addressOverride.name;
    } else {
      this.props.values.addressOverrides.push({
        clientUnitId: addressOverride.clientUnitId,
        id: addressOverride.id,
        name: addressOverride.name
      });
    }

    this.props.setFieldValue("addressOverrides", [
      ...this.props.values.addressOverrides
    ]);
  }

  handleAddressOverrideDelete(clientUnitId) {
    const addressOverride = this.props.values.addressOverrides.find(
      a => a.clientUnitId === clientUnitId
    );
    removeFromArray(this.props.values.addressOverrides, addressOverride);
    this.props.setFieldValue("addressOverrides", [
      ...this.props.values.addressOverrides
    ]);
  }

  handleAddressOverrideClick(addressOverride) {
    this.props.openModal(
      (resolve, reject) => (
        <AddressOverrideModal
          userId={this.props.values.id}
          onSuccess={this.handleAddressOverrideChange}
          onDelete={this.handleAddressOverrideDelete}
          resolveModal={resolve}
          closeModal={reject}
          addressOverride={addressOverride}
        />
      ),
      { title: "Address override" }
      // @todo - fix types for modal ts actions
    );
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      isNew,
      ...restProps
    } = this.props;

    return (
      <Grid.Body>
        <Grid.Row>
          <Grid.Cell>
            <Field
              required
              type="text"
              name="name"
              label="First Name"
              placeholder=""
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={
                this.props.isLoading || !values.isActive || this.props.disabled
              }
              error={touched.name && errors.name}
            />
          </Grid.Cell>
          <Grid.Cell>
            <Field
              required
              type="text"
              name="surname"
              label="Last Name"
              placeholder=""
              value={values.surname}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={
                this.props.isLoading || !values.isActive || this.props.disabled
              }
              error={touched.surname && errors.surname}
            />
          </Grid.Cell>
        </Grid.Row>
        <Grid.Row>
          <Grid.Cell>
            <Field
              required
              type="text"
              name="userName"
              label="User Name"
              placeholder=""
              value={values.userName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={
                this.props.isLoading || !values.isActive || this.props.disabled
              }
              error={touched.userName && errors.userName}
            />
          </Grid.Cell>
          <Grid.Cell>
            <Field
              required
              type="text"
              name="emailAddress"
              label="E-mail"
              placeholder=""
              value={values.emailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={
                this.props.isLoading || !values.isActive || this.props.disabled
              }
              error={touched.emailAddress && errors.emailAddress}
            />
          </Grid.Cell>
        </Grid.Row>
        {!isNew && (
          <Grid.Row>
            <Grid.Cell>
              <Position.PasswordToggle>
                <Button
                  onClick={debounce(
                    () => this.handleSendEmail(location.pathname),
                    500
                  )}
                  scale="lg"
                >
                  Send Password Reset Email
                </Button>
              </Position.PasswordToggle>
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Cell>
            <Telephone
              reduce
              name="phoneNumber"
              id="phoneNumber"
              label="Phone Number"
              placeholder="Phone Number..."
              value={values.phoneNumber}
              disabled={
                this.props.isLoading || !values.isActive || this.props.disabled
              }
              onChange={this.handlePhoneNumberChange}
              error={touched.phoneNumber && errors.phoneNumber}
            />
          </Grid.Cell>
          <Grid.Cell>
            <Number
              positiveOnly
              integer
              required
              name="limit"
              label="Spend Limit"
              placeholder="Spend Limit"
              value={values.limit}
              step={1}
              disabled={
                this.props.isLoading ||
                values.unlimited ||
                !values.isActive ||
                this.props.disabled
              }
              onChange={value => restProps.setFieldValue("limit", value)}
              onBlur={handleBlur}
              error={touched.limit && errors.limit}
            />
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell>
            <Select
              required
              name="spendingTerm"
              label="Spend Term"
              placeholder="Spend Term..."
              labelKey="displayValue"
              valueKey="id"
              clearable={false}
              value={values.spendingTerm}
              options={values.spendingTermOptions}
              isLoading={values.spendingTermStatus === "loading"}
              onChange={value => {
                if (value) {
                  const selectedTerm = values.spendingTermOptions.find(
                    term => term.id === value.id
                  );
                  restProps.setFieldValue("spendingTerm", selectedTerm);
                }
              }}
              disabled={
                this.props.isLoading ||
                values.unlimited ||
                !values.isActive ||
                this.props.disabled ||
                values.spendingTermStatus === "loading"
              }
              error={touched.spendingTerm && (errors.spendingTerm as string)}
            />
          </Grid.Cell>
          <Grid.Cell>
            <Position.UnlimitedCheckbox>
              <Checkbox
                value={values.unlimited}
                label="Unlimited"
                disabled={
                  this.props.isLoading ||
                  !values.isActive ||
                  this.props.disabled
                }
                onChange={value => restProps.setFieldValue("unlimited", value)}
                name="unlimited"
                id="unlimited"
              />
            </Position.UnlimitedCheckbox>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell>
            <ClientUnitsTree
              label="Client Units *"
              nodes={values.clientUnits}
              addressOverrides={values.addressOverrides}
              disabled={
                this.props.isLoading || !values.isActive || this.props.disabled
              }
              addressOverridesStatus={values.addressOverridesStatus}
              isLoading={!values.clientUnits || !values.clientUnits.length}
              activeIds={values.clientUnitIds}
              isNew={this.props.isNew}
              onActiveIdsChange={activeIds => {
                this.props.setFieldValue("clientUnitIds", activeIds);
              }}
              onAddressOverrideClick={this.handleAddressOverrideClick}
              blockActiveChildren
              error={touched.clientUnitIds && errors.clientUnitIds}
            />
          </Grid.Cell>
        </Grid.Row>
      </Grid.Body>
    );
  }
}
export default connect(
  null,
  {
    openModal: openCustomModalCallback,
    openConfirm,
    alertModal: alert
  }
)(withGranted(UserFormInfo));
